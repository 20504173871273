<template>
	<div class="page bg-white">
		<ul>
			<li class="precautions-item" v-for="(i , index) in listData" :key="i.title">
				<div class="precautions-item__title fs-15 b">{{ index + 1 }}.{{ i.title }}</div>
				<div class="precautions-item__text fs-13">{{ i.text }}</div>
			</li>
		</ul>
		<RiskAvoidanceGuide v-bury="'11100'" text-center="right" color-class="color-danger"/>

	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'precautions',
	components: {
		RiskAvoidanceGuide: defineAsyncComponent(() => import('../../components/RiskAvoidanceGuide'))
	},
	data() {
		return {
			listData: [
				{
					title: '为什么要绑定微信号？',
					text: '播货助手是需要借助微信号才能使用，并能够把素材转发到各个群内。'
				},
				{
					title: '用其他微信号绑定是否影响收入？？',
					text: '小助手是和店主的店铺账号互相绑定，所以使用任意微信号均可扫码登录小助手，不会影响店铺收益。'
				},
				{
					title: '一定要用小号绑定吗？',
					text: '使用常用微信号或者其他微信号均可登录小助手，但考虑微信风控，建议使用小号的。'
				},
				{
					title: '绑定微信号有什么要求？',
					text: '微信号绑定小助手期间，不能登陆电脑端或IPAD，否则会导致机器人掉线。'
				},

			]
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	padding: 32px 16px;
}
.precautions-item{
	margin-bottom: 32px;
	&:last-of-type{
		margin-bottom: 48px;
	}
	.precautions-item__title{
		color: #3C3C3C;
	}
	.precautions-item__text{
		margin-top: 6px;
		color: rgba(60, 60, 60, .6)
	}
}
</style>
